<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
      </div>
      <div class="d-flex jc-center body-box"  style="height:100%; font-size: 0.8rem;color:#ffc103" >
        <dv-scroll-board :config="config"  />
      </div>
    </div>
  </div>
</template>

<script>
// import {getWageListapi} from '../../libs/axios';
export default {
  data() {
    return {
      config: {
        rowNum: 8, //表格行数
        header: ["姓名", "部门","职位","金额"],
        data:[
["王能耀","生产部","充绒","8747￥"],
["刘静","生产部","车工","8011￥" ],
["黄小燕","生产部","车工","6833￥" ],
["魏春红","生产部","车工","6740￥" ],
["滕光华","生产部","封口","6589￥" ],
["彭桂英","生产部","车工","6413￥" ],
["胡延珍","1线","车工","6375￥" ],
["董长梅","生产部","车工","6297￥" ],
["裴燕玲","生产部","车工","6199￥" ],
["饶本荣","生产部","车工","6190￥" ],
["裴明莉","生产部","车工","6175￥" ],
["李伟","生产部","车工","6137￥" ],
["蔡大秀","生产部","车工","6113￥" ],
["李昌莲","生产部","车工","5971￥" ],
["张连芳","生产部","车工","5960￥" ],
["魏玲燕","生产部","车工","5959￥" ],
["李开秀","生产部","模板","5882￥" ],
["黄小慧","生产部","车工","5846￥" ],
["林道华","生产部","车工","5821￥" ],
["骆婷","生产部","车工","5806￥" ],
["吴萍","生产部","车工","5711￥" ],
["张凤玲","生产部","车工","5698￥" ],
["程燕","生产部","车工","5687￥" ],
["易小朋","生产部","车工","5640￥" ],
["刘星星","模板组","模板","5622￥" ],
["王贻林","模板组","模板","5579￥" ],
["李红杰","模板组","模板","5558￥" ],
["余玲芝","生产部","车工","5513￥" ],
["仲近梅","生产部","模板","5459￥" ],
["李云","生产部","车工","5437￥" ],
["刘峰","生产部","车工","5427￥" ],
["裴仁珍","生产部","车工","5376￥" ],
["孙丹丹","模板组","模板","5361￥" ],
["余克凤","生产部","车工","5318￥" ],
["吴玉军","模板组","模板","5309￥" ],
["张启芳","生产部","车工","5285￥" ],
["曹栋","模板组","模板","5281￥" ],
["郑春云","生产部","车工","5254￥" ],
["官同芝","生产部","车工","5242￥" ],
["曾召贵","模板组","模板","5232￥" ],
["文宏萍","模板组","模板","5212￥" ],
["赵继云","生产部","车工","5122￥" ],
["李芳芳","生产部","车工","5121￥" ],
["孙文枝","生产部","车工","5110￥" ],
["吴秀枝","生产部","车工","5065￥" ],
],
        headerHeight: 65,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true, 
        columnWidth: [100,380,430,430],
        align: ["center"]
      }
    };
  },
  components: {},
  mounted() {
    this.init();
    //  setInterval(this.init,120000);
  },
  methods: {
    init() {
      // this.getWageList();
    },
    //  getWageList() {
       
    //   this.loading = true;
    //   var workid=this.$store.state.workid;
    //   var seqWageGuids=this.$store.state.seqWageGuids;
    //   getWageListapi({workid:workid,seqGuids:seqWageGuids}).then((res) => {
    //       this.loading = false;
    //       this.$set(this.config,  this.config.data,res.data);
    //       this.config.data=res.data;
    //   });
    // },
  }
};
</script>

<style lang="scss">
#centreRight1 {
  padding: 0.2rem;
  height: 13.75rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  font-size: 0.9rem;
  .bg-color-black {
    height: 100%;
    border-radius: 0.125rem;
    font-size:0.9rem;
  }
  .text {
    font-size: 0.9rem;
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
    font-size: 0.9rem;
  }
  .header-item{
    font-size: 0.9rem;
  }
    .row-item{
    font-size: 0.9rem;
    color:"#ffc103"
  }
  .ceil{
    color: rgb(255, 193, 3);
  }
}
</style>